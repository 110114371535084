<template>
  <div>
    <div
      class="w-full bg-black flex flex-col justify-center items-center min-h-screen pt-20"
    >
      <menu-bar></menu-bar>
      <div class="lg:w-192 bg-white rounded p-8">
        <h1 class="font-impact text-4xl">Our Approach To Cookies</h1>
        <hr class="mb-2 mt-4" />
        <p class="text-xl">{{ consentMessage }}</p>
        <button
          class="bg-brand-orange rounded px-2 py-1 text-white shadow mt-4"
          @click="revoke"
          v-if="this.$store.state.consent !== null"
        >
          Revoke Your Consent Choice
        </button>
        <button
          class="bg-brand-orange rounded px-2 py-1 text-white shadow mt-4"
          @click="revoke"
          v-if="this.$store.state.consent === null"
        >
          Make Your Consent Choice
        </button>
        <hr class="mt-4" />
        <p class="mt-4">
          We are committed to protecting your privacy. Some cookies are required
          to run this site, but we've done all we can to minimise the intrusion
          on your privacy. Where we can, we anonymise IP addresses and nothing
          is kept for longer than 24 hours. The cookies we require help us
          maintain a good level of security, and enable things like live chat.
        </p>
        <p class="mt-4 ">
          Whichever your choice, we don't keep any identifiable information
          (unless we think your behaviour is suspicious). In the event that we
          identify suspicious behaviour we may store your IP address for up to
          30 days.
        </p>
        <p v-if="$store.state.consent" class="mt-4">
          The optional cookies that you have allowed are used by Google. They
          help us see how you navigate the site, and we use that information to
          make improvements.
        </p>
        <p class="mt-4">
          We store your consent choice for 30 days. Then, we'll ask you again
        </p>
      </div>
    </div>
    <site-footer></site-footer>
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar";
import SiteFooter from "@/components/SiteFooter";
export default {
  name: "Cookie",
  title: "RYE Group's Approach To Cookies",
  components: { SiteFooter, MenuBar },
  computed: {
    consentMessage() {
      return this.$store.state.consent
        ? "You have consented to our use of cookies that help us improve."
        : "You have not given consent to use our optional cookies, and we're not using them.";
    }
  },
  methods: {
    revoke() {
      document.cookie = "consent=granted;max-age=0";
      window.location.href = "/";
    }
  }
};
</script>

<style scoped></style>
