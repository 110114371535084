<template>
  <div>
    <menu-bar></menu-bar>

    <slide2 :visible="load" :hidemore="true"></slide2>
    <top-section>
      <template #title-1>Health & Safety Management Systems</template>
      <template #body-1
        >Effective management systems can help you manage health and safety.
        Does your company need to meet national and international standards,
        sector specific frameworks or just maintain it's own in house standards?
        Rye Safety can help with planning, implementation and engagement.<br /><br />
        A formalised management system may not be the most appropriate model for
        your business. We can help you assess the level of risk in your
        organisation and create a proportionate, tailored solution.
      </template>
      <template #button-1>Get In Touch</template>

      <template #mid-1>
        <span class="text-6xl font-black w-3/4">
          693000
        </span>
        <span class="text-2xl font-bold mt-2"
          >Workers Sustaining Non-Fatal Injuries</span
        >
        <span class="font-bold mt-2">HSE - 2019/20</span>
      </template>

      <template #title-2>Risk Assessments & Method Statement</template>
      <template #body-2>
        General, business wide risk assessments and specific area assessments
        will identify the at risk areas of your operation and provide a route to
        safer working. Systems must be in place for the protection of your
        workforce.
        <span class="font-bold">RYE Safety</span> has experience across
        industries and can help you identify threats that otherwise may expose
        your business to unnecessary risk. <br /><br />
        Our method statements describe a logical sequence that show your
        operation has understood the threats to safety in high risk working
        practices. Your method statements should allow you to effectively plan
        work and the health and safety resources required.
      </template>
      <template #button-2>Help With RAMS</template>
      <template #mid-2>
        <span class="text-6xl font-black w-3/4">
          38.8 Million
        </span>
        <span class="text-2xl font-bold mt-2"
          >Working Days Lost Due To Work-Related Illness And Workplace
          Injury</span
        >
        <span class="font-bold mt-2">HSE - 2019/20</span>
      </template>

      <template #title-3>Accident & Incident Investigation</template>
      <template #body-3>
        An effective investigation requires a methodical, structured approach to
        information gathering, collation and analysis. As an experienced Health
        & Safety Consultancy, <span class="font-bold">RYE Safety</span> can
        conduct an investigation, the findings of which will help you prevent
        recurrences of incidents and improve your overall management of risk.<br /><br />
        We frequently advise and act on
        <span class="font-bold">RIDDOR</span> reportable events.
        <router-link to="/contact"
          ><span class="font-bold">Contact RYE Safety</span></router-link
        >
        today to begin effective improvement.
      </template>
      <template #button-3>Request Assistance</template>

      <template #mid-3>
        <img src="/img/hs.png" alt="Health And Safety Tiles" class="mt-96" />
      </template>
    </top-section>

    <bottom-section>
      <template #title-1>Training & Awareness</template>
      <template #body-1
        >The Health and Safety at Work etc Act 1974 requires you to provide
        whatever information, instruction, training and supervision as is
        necessary to ensure, so far as is reasonably practicable, the health and
        safety at work of your employees. <br /><br />
        Whether you are an employer or self-employed, are you sure that you’re
        up to date with how to identify the hazards and control the risks from
        your work? <span class="font-bold">RYE Safety's</span> experience and
        position in the community is an asset that will provide an effective, up
        to date and engaging solution to your training and awareness needs.
      </template>
      <template #button-1>Our Training Packages</template>

      <template #title-2>ISO 45001 Preparation & Compliance Auditing</template>
      <template #body-2
        >ISO 45001:2018 is an international standard for the Occupational Health
        & Safety (OH&S) management system. The framework for establishment,
        implementation and effective improvement and maintenance of an OH&S
        management system will lay the foundation to demonstrate commitment to
        avoiding workplace accidents & disease. <br /><br />
        <span class="font-bold">RYE Safety</span> will assist in all aspects of
        ISO 45001 to ensure your business is compliant, and protects against
        legal action and costly mistakes.
      </template>
      <template #button-2>ISO 45001</template>

      <template #title-3>SSIP Accreditation</template>
      <template #body-3
        >SSIP Accreditation demonstrates your commitment to and competency with
        health and safety practices and procedures. Simply put, SSIP
        accreditation will give your potential clients confidence in your
        ability to work safely. <br /><br />
        <span class="font-bold">RYE Safety</span> has experience in preparation,
        and as a SSIP accredited company can guide your business through the
        process.
      </template>
      <template #button-3>Help With SSIP</template>

      <template #testimonial>
        "I just wanted to share a quick note and let you know that you guys do a
        really good job. I’m glad I decided to work with you. It’s really great
        how easy your websites are to update and manage. I never have any
        problem at all"
      </template>
    </bottom-section>

    <div class="h-96"></div>
    <rye-logo class="w-1/2 mx-auto mb-40"></rye-logo>

    <site-footer />
  </div>
</template>

<script>
// @ is an alias to /src

import TopSection from "@/components/TopSection";
import BottomSection from "@/components/BottomSection";
import SiteFooter from "@/components/SiteFooter";
import RyeLogo from "@/components/RyeLogo";
import Slide2 from "@/components/Slide2";
import MenuBar from "@/components/MenuBar";
export default {
  name: "HealthSafety",
  meta:
    "The Health and Safety at Work etc Act 1974 requires you to provide whatever information, instruction, training and supervision as is necessary to ensure, so far as is reasonably practicable, the health and safety at work of your employees.  RYE Safety provides a broad range of consulting services.  Contact RYE Safety Today.",
  title: "RYE Group for Health & Safety",
  components: {
    MenuBar,
    Slide2,
    RyeLogo,
    SiteFooter,
    BottomSection,
    TopSection
  },
  data() {
    return {
      load: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 300);
  }
};
</script>

<style scoped></style>
