<template>
  <div class="">
    <menu-bar></menu-bar>
    <div class="h-screen">
      <slide3 :visible="load" :hidemore="true"></slide3>
    </div>
    <top-section>
      <template #title-1>Effective Environmental Protection</template>
      <template #body-1
        >Shrink wrapped scaffolding provides effective protection from the
        weather. Each joint is sealed with a process of heat and pressure that
        creates a permanent weld. This method allows us to provide a superior
        level of protection and environmental containment that is effective and
        low maintenance.<br /><br />
        The continuously bonded skin can protect the environment against the
        effects of shot blasting, concrete cutting, asbestos removal and more.
        Built with a UV inhibitor, Rhino Wrap lasts longer. Call
        <span class="font-bold">Covered In Shrink Wrap</span> and meet a higher
        standard.
      </template>
      <template #button-1>Environmental Protection</template>

      <template #mid-1>
        <img
          src="/img/cis.png"
          alt="Covered In Shrink Wrap Logo"
          class="mt-40 w-4/5 sm:w-auto max-h-full max-w-full"
        />
      </template>

      <template #title-2>A Low Maintenance Solution</template>
      <template #body-2
        >Our shrink wrapping installation process results in a drum tight,
        robust solution. In addition to our overlapped, welded joints we use a
        heating process to further shrink all of the panels. Attached to the
        individual scaffold tubes our product will not flap around in the wind,
        it will not self destruct. <br /><br />There's no need to pay
        scaffolders or contractors to repair loose and flapping sheets anymore.
        <span class="font-bold">Covered In Shrink Wrap</span> can install an
        effective, robust solution.
      </template>
      <template #button-2>Low Maintenance</template>

      <template #mid-2>
        <div
          class="h-full w-screen bg-brand-orange my-40 bg-center bg-contain bg-no-repeat"
          style="background-image:url('/img/rhino-approved.png')"
        ></div>
      </template>

      <template #title-3>Image Matters</template>
      <template #body-3
        >When privacy is a requirement, choose shrink wrap. In areas where an
        impact on local residents must be mitigated
        <span class="font-bold">Covered In Shrink Wrap</span> can provide an
        opaque, seamless screen around any structure. The drum tight nature of
        our scaffold protection can be much quieter than traditional sheeting.
        With shrink wrap we can deal with your most common issues such as
        sheeting noise. It's all a part of our professional installation
        process. <br /><br />
        <span class="font-bold">We can cover anything.</span> Bridges, boats,
        high rise buildings - we cover it.
      </template>
      <template #button-3>Privacy & Professionalism</template>

      <template #mid-3>
        <div class="pt-80">
          <img
            src="/img/coll-phone.png"
            alt="Shrink Wrapped Scaffold"
            class="w-full sm:w-auto my-60 xl:hidden"
          />
          <img
            src="/img/collage-sw.png"
            alt="Shrink Wrapped Scaffold"
            class="hidden xl:block"
          />
        </div>
      </template>
    </top-section>

    <bottom-section>
      <template #title-1>We're Rhino Approved</template>
      <template #body-1>
        Rhino Shrink Wrap has been leading manufacture from their ISO9001
        facility for over 15 years and we're product that
        <span class="font-bold">Covered In Shrink Wrap</span> is an approved
        installer. Rhino Wrap is available in a material that's flame retardant
        to EN13501, and it's a robust consistent material.<br /><br />
        Our team are fully trained, experienced on the job and approved. Let's
        discuss your requirements today.
      </template>
      <template #button-1>Enquire About Our Status</template>

      <template #title-2
        >Covered In Shrink Wrap is a Specialist Contractor</template
      >
      <template #body-2
        >Many installers of shrink wrap provide the service as a 'side offering'
        alongside another business. Unfortunately, this doesn't always achieve
        the best results.<br /><br />
        At Covered In Shrink Wrap we only offer one service, expert installation
        of shrink wrap products.
        <span class="font-bold">Covered In Shrink Wrap</span> has a level of
        mastery in installation achieved through thorough, continuous training
        and experience.
      </template>
      <template #button-2>Talk To a Specialist</template>

      <template #title-3>Get In Touch</template>
      <template #body-3
        ><router-link to="contact"
          ><span class="font-bold">Contact Us Today</span></router-link
        >
        to chat about your project. We will provide you with advice in the first
        instance, then we can help you understand the benefits of shrink
        wrapping. Not only can we provide a cost effective solution but also a
        low maintenance, robust and environmentally sound one. <br /><br />We're
        a nationwide company with the experience and skills to provide you with
        the best shrink wrap installation on offer.
      </template>
      <template #button-3>Your Next Project</template>

      <template #testimonial>
        "I just wanted to share a quick note and let you know that you guys do a
        really good job. I’m glad I decided to work with you. It’s really great
        how easy your websites are to update and manage. I never have any
        problem at all"
      </template>
    </bottom-section>

    <div class="h-192"></div>
    <div class="bg-black pt-40">
      <img
        src="/img/logo-banner-c.png"
        alt="Covered In Shrink Wrap"
        class="mx-auto"
      />
    </div>

    <site-footer />
  </div>
</template>

<script>
// @ is an alias to /src

import TopSection from "@/components/TopSection";
import BottomSection from "@/components/BottomSection";
import SiteFooter from "@/components/SiteFooter";
import MenuBar from "@/components/MenuBar";
import Slide3 from "@/components/Slide3";
export default {
  name: "ShrinkWrap",
  title: "RYE Group for Shrink Wrapping",
  meta:
    "Shrink wrapped scaffolding provides effective protection from the weather. Each joint is sealed with a process of heat and pressure that creates a permanent weld. This method allows us to provide a superior  level of protection and environmental containment that is effective and  low maintenance.",
  components: {
    Slide3,
    MenuBar,
    SiteFooter,
    BottomSection,
    TopSection
  },
  data() {
    return {
      load: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 300);
  }
};
</script>

<style scoped></style>
