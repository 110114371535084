<template>
  <div ref="container" class="mt-80 sm:mt-144  sm:mb-96">
    <div ref="first" id="first-element" class="flex flex-col sm:flex-row">
      <div class="left-title">
        <h2 class="text-right">
          <slot name="title-1"></slot>
        </h2>
      </div>
      <p class="relative text-left sm:pl-4 xl:pl-8">
        <svg class="left-0" viewBox="0 0 10 200" width="10px" height="100%">
          <polyline
            ref="firstLine"
            points="5,-50 5,550"
            stroke="#e8732c"
            stroke-width="8"
          ></polyline>
        </svg>

        <slot name="body-1"></slot>
        <router-link to="/contact">
          <button
            class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
          >
            <slot name="button-1"></slot>
          </button>
        </router-link>
      </p>
    </div>
    <div
      class="h-192 flex flex-col justify-center items-center lg:px-40 text-center"
    >
      <slot name="mid-1"></slot>
    </div>

    <div ref="second" id="second-element" class="flex flex-col sm:flex-col">
      <h2 class="w-full text-right sm:w-3/4"><slot name="title-2"></slot></h2>
      <p>
        <slot name="body-2"></slot
        ><router-link to="/contact">
          <button
            class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
          >
            <slot name="button-2"></slot>
          </button>
        </router-link>
      </p>
    </div>
    <div
      class="h-192 flex flex-col justify-center items-center lg:px-40 text-center"
    >
      <slot name="mid-2"></slot>
    </div>

    <div id="third-element" ref="third" class="flex flex-col sm:flex-row">
      <p class="mr-4 relative">
        <svg
          class="-right-8 absolute"
          viewBox="0 0 8 100"
          width="10px"
          height="100%"
        >
          <polyline
            ref="firstLine"
            points="5,-50 5,550"
            stroke="#e8732c"
            stroke-width="8"
          ></polyline>
        </svg>
        <slot name="body-3"></slot
        ><router-link to="/contact">
          <button
            class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
          >
            <slot name="button-3"></slot>
          </button>
        </router-link>
      </p>
      <div>
        <h2 class="w-full"><slot name="title-3"></slot></h2>
      </div>
    </div>
    <div
      class="h-192 flex flex-col justify-center items-center lg:px-40 text-center relative"
    >
      <slot name="mid-3"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopSection",
  data() {
    return { svgPath: "", svgWidth: "0", svgHeight: "0", activeRef: "" };
  },
  mounted() {
    this.svgWidth = window.innerWidth;
    this.svgHeight = document.body.clientHeight;

    window.addEventListener("scroll", () => {
      let firstTop = this.$refs.first.getBoundingClientRect().y;
      let w = window.innerHeight;
      let halfWin = w / 5;
      if (firstTop < halfWin) {
        let op = firstTop / halfWin;
        this.$refs.first.style.opacity = op;
      }

      if (firstTop > halfWin * 2) {
        let op = firstTop / (halfWin * 2);
        this.$refs.first.style.opacity = 2 - op;
      }

      let secondTop = this.$refs.second.getBoundingClientRect().y;
      if (secondTop < halfWin) {
        let op = secondTop / halfWin;
        this.$refs.second.style.opacity = op;
      }

      if (secondTop > halfWin * 2) {
        let op = secondTop / (halfWin * 2);
        this.$refs.second.style.opacity = 2 - op;
      }

      let thirdTop = this.$refs.third.getBoundingClientRect().y;
      if (thirdTop < halfWin) {
        let op = thirdTop / halfWin;
        this.$refs.third.style.opacity = op;
      }

      if (thirdTop > halfWin * 2) {
        let op = thirdTop / (halfWin * 2);
        this.$refs.third.style.opacity = 2 - op;
      }
    });
  },
  methods: {}
};
</script>

<style scoped>
h2 {
  @apply text-xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-6xl font-black;
}

p {
  @apply text-sm xl:text-lg;
}

#first-element,
#second-element,
#third-element {
  @apply mx-auto w-10/12  h-48 sm:h-96 justify-center items-center
  sm:w-11/12
  lg:w-10/12
  2xl:w-2/3;
}

#first-element svg,
#third-element svg {
  @apply absolute top-0 z-0 hidden sm:block;
}

#first-element .left-title {
  @apply sm:pr-2 md:pr-8 relative w-full sm:w-5/12;
}

#first-element p {
  @apply pr-4 w-full border-t-2 border-r-2 border-brand-orange pt-3 mt-4
   sm:border-l-2 sm:w-3/4 sm:mt-0 sm:pt-0 sm:border-none;
}

#second-element p {
  @apply border-l-2 border-t-2 border-brand-orange w-full pt-4 pl-6 mt-4
  md:w-3/4
  lg:w-3/4
  xl:w-3/4;
}

#third-element p {
  @apply text-right pr-2 w-full order-2 sm:order-1 border-r-2 border-t-2 border-brand-orange pt-2 pr-2 mt-4 sm:mt-0 sm:p-0 sm:border-none;
}

#third-element div {
  @apply relative sm:pl-8 sm:text-left order-1 sm:order-2 sm:w-5/12;
}
</style>
