<template>
  <div class="relative top-0 left-0 w-full z-40">
    <div ref="trigger"></div>
    <div
      class="fixed z-50 top-1/2 left-1/2 w-80 sm:w-192 h-96 -ml-40 sm:-ml-96 -mt-44 xl:w-192 xl:h-192 xl:-ml-96 xl:-mt-96 flex items-center"
      :class="{ hidden: !displayFixed }"
    >
      <div
        class="outer opacity-100 border-l-8 border-brand-orange pl-4 lg:py-8"
        ref="first"
      >
        <h2 class="mb-2 lg:mb-10 transition-all duration-500 w-full">
          <slot name="title-1"></slot>
        </h2>
        <p class="transition-all delay-200 duration-500">
          <slot name="body-1"></slot
          ><router-link to="/contact">
            <button
              class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
            >
              <slot name="button-1"></slot>
            </button>
          </router-link>
        </p>
      </div>

      <div
        class="outer opacity-0 border-r-8 border-brand-orange pr-4 lg:py-8 text-right"
        ref="second"
      >
        <h2 class="mb-2 lg:mb-10  transition-all duration-1000 w-full">
          <slot name="title-2"></slot>
        </h2>
        <p class="transition-all delay-300 duration-1000">
          <slot name="body-2"></slot
          ><router-link to="/contact">
            <button
              class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
            >
              <slot name="button-2"></slot>
            </button>
          </router-link>
        </p>
      </div>

      <div
        class="outer opacity-0  border-l-8 border-brand-orange pl-4 lg:py-8"
        ref="third"
      >
        <h2 class="mb-2 lg:mb-10  transition-all duration-1000 w-full">
          <slot name="title-3"></slot>
        </h2>
        <p class="transition-all delay-300 duration-1000">
          <slot name="body-3"></slot
          ><router-link to="/contact">
            <button
              class="mx-auto block bg-brand-orange text-white px-4 py-2 rounded shadow-lg mt-8"
            >
              <slot name="button-3"></slot>
            </button>
          </router-link>
        </p>
      </div>
    </div>

    <div style="height:4200px"></div>

    <div class="outer px-8 lg:px-40 py-8 flex">
      <div class="mb-20 flex flex-col md:flex-row items-center justify-center">
        <img src="/img/rec.png" alt="Recycle" class="w-24" />
        <p class="mt-12 md:mt-0 md:ml-8 max-w-2xl font-impact text-center">
          We are committed to protecting the environment as well as your
          projects and investments, we offer recycling programs that can provide
          you with the best ways to meet your waste and recycling targets
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomSection",
  components: {},
  data() {
    return {
      activeRef: "",
      displayFixed: false
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      let s = window.scrollY;
      let o = this.offset(this.$refs.trigger);
      if (window.scrollY < o - 300) {
        this.displayFixed = false;
      }

      if (s > o - 300 && s < 500 + o) {
        this.displayFixed = true;

        let travel = s - o + 300;
        let op = travel / 800;
        this.$refs.first.style.opacity = op;
        this.$refs.second.style.opacity = 0;
        this.$refs.third.style.opacity = 0;
      }

      if (s > 500 + o && s < 1000 + o) {
        this.displayFixed = true;

        let travel = s - 500 - o;
        let op = 1 - travel / 500;
        this.$refs.first.style.opacity = op;
        this.$refs.second.style.opacity = 0;
        this.$refs.third.style.opacity = 0;
      }

      if (s > 1000 + o && s < 1500 + o) {
        this.displayFixed = true;

        let travel = s - 1000 - o;
        let op = travel / 500;
        this.$refs.second.style.opacity = Math.max(0, Math.min(1, op));
        this.$refs.first.style.opacity = 0;
        this.$refs.third.style.opacity = 0;
      }

      if (s > 1500 + o && s < 2000 + o) {
        this.displayFixed = true;

        let travel = s - 1500 - o;
        let op = 1 - travel / 500;
        this.$refs.second.style.opacity = Math.max(0, Math.min(1, op));
        this.$refs.first.style.opacity = 0;
        this.$refs.third.style.opacity = 0;
      }

      if (s > 2000 + o && s < 2500 + o) {
        this.displayFixed = true;

        let travel = s - 2000 - o;
        let op = travel / 500;
        this.$refs.third.style.opacity = Math.max(0, Math.min(1, op));
        this.$refs.first.style.opacity = 0;
        this.$refs.second.style.opacity = 0;
      }

      if (s > 2500 + o && s < 3000 + o) {
        this.displayFixed = true;
        let travel = s - 2500 - o;
        let op = 1 - travel / 500;
        this.$refs.third.style.opacity = Math.max(0, Math.min(1, op));
        this.$refs.first.style.opacity = 0;
        this.$refs.second.style.opacity = 0;
      }

      if (s > 3000 + o && s < 3300 + o) {
        this.displayFixed = false;
      }
    });
  },
  methods: {
    offset(el) {
      let rect = el.getBoundingClientRect(),
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    }
  }
};
</script>

<style scoped>
h2 {
  @apply text-2xl lg:text-6xl font-black;
}

p {
  @apply text-lg;
}

.outer {
  @apply flex flex-col justify-center items-center h-auto absolute left-0 w-full;
}
</style>
