<template>
  <div>
    <menu-bar></menu-bar>

    <scaff-frame :visible="load" :hidemore="true"></scaff-frame>
    <top-section>
      <template #title-1>Statutory Scaffold Inspection</template>
      <template #body-1
        >Scaffolding Inspections must be carried out by a competent person whose
        combination of knowledge, training and experience is appropriate for the
        type and complexity of the scaffold.
        <span class="font-bold">RYE Safety</span> has the experience to conduct
        all inspections, whether routine or following potentially risk elevating
        events. Our inspection reports can highlight defects or matters
        affecting safety, helping you identify and remedy recurring issues.
        <br /><br />
        We frequently perform <span class="font-bold">Tie Testing</span> using
        our fully calibrated anchor testing kit. Call us today and eliminate any
        doubt.
      </template>
      <template #button-1>Book An Inspection</template>
      <template #mid-1>
        <div class="h-3/4 w-full bg-r1s xl:bg-r1 bg-no-repeat bg-center"></div>
      </template>

      <template #title-2>Temporary Works</template>
      <template #body-2
        >Temporary works (TW) are the parts of a construction project that are
        needed to enable the permanent works to be built. <br /><br />
        BS5975:2019 provides an industry consensus view on what is considered to
        be good practice. The legal requirement is that the party in control
        must ensure that work is allocated and carried out in a manner that does
        not create unacceptable risk of harm to workers or members of the
        public. On projects with relatively simple Temporary Works needs, you
        may choose not to appoint a Temporary Works Coordinator. However, you
        must still make sure that Temporary Works are properly managed to ensure
        safety. <span class="font-bold">RYE Safety</span> will provide a cost
        effective and knowledgeable partner for your business.
      </template>
      <template #button-2>Talk About Temporary Works</template>

      <template #mid-2>
        <div class="h-3/4 w-full bg-r2s xl:bg-r2 bg-no-repeat bg-center"></div>
      </template>

      <template #title-3>Sector Specific Health & Safety</template>
      <template #body-3
        >Although the law requires that employers and self-employed contractors
        assess the risk from work at height and go on to organise and plan the
        work so it is carried out safely, that's just one of the factors in
        ensuring the safety of those working with and being in the area of
        scaffold installations. Our hands on experience in the Scaffolding
        Industry puts us in the best place to advise you on safety of
        installation, protection of the public and your workforce.
      </template>
      <template #button-3>Health & Safety Advice</template>

      <template #mid-3>
        <div class="h-3/4 w-full bg-r3s xl:bg-r3 bg-no-repeat bg-center"></div>
      </template>
    </top-section>

    <div>
      <div
        class="col-span-12  flex flex-col justify-center items-center xl:col-span-4 h-96 bg-black border-t-2 border-b-2 border-brand-orange -mt-40"
      >
        <div
          class="border-brand-orange border-2 p-8 text-center rounded transform hover:scale-105 transition-all"
        >
          <router-link to="/contact">
            <p class="font-impact text-4xl text-brand-orange mb-8 underline">
              CONTACT RYE SAFETY
            </p>
          </router-link>
          <p class="font-impact text-3xl text-white">
            About Your Scaffolding Works
          </p>
        </div>
      </div>
    </div>

    <bottom-section>
      <template #title-1>Management Services</template>
      <template #body-1
        >Talk to <span class="font-bold">RYE Safety</span> about your next
        Scaffolding Project. We offer a nationwide Scaffold Management service
        and will provide a cost effective, fully managed solution to your
        project. <br /><br />
        Our highly trained and experienced management team is on call, ready to
        assist your next project.
      </template>
      <template #button-1>Scaffold Management Services</template>

      <template #title-2>PQQ Completion</template>
      <template #body-2
        >Pre-Qualifying Questionnaires are used by most procurement departments
        to review your suitability as a potential supplier.
        <span class="font-bold">RYE Safety</span> can help you present
        information on health & safety, critical statistics, your business
        quality and more. <br /><br />
        Let us guide you through this process using our industry experience, and
        we'll help you win the business you deserve.
      </template>
      <template #button-2>PDQ Assistance</template>

      <template #title-3>PC Contractor Interface</template>
      <template #body-3
        ><span class="font-bold">RYE Safety</span> acts an independent mediator
        between parties at dispute. Our speciality is in taking technical detail
        and presenting it at a level appropriate for each party. Our aim is to
        reduce the impact of all dispute. Less downtime, less friction and more
        results.
      </template>
      <template #button-3>PC Contractor Interface</template>

      <template #testimonial>
        "!!! RENE - Need a testimonial for here. I can ask Rob if you don't have
        one"
      </template>
    </bottom-section>

    <div class="h-192"></div>
    <site-footer />
  </div>
</template>

<script>
// @ is an alias to /src

import TopSection from "@/components/TopSection";
import BottomSection from "@/components/BottomSection";
import SiteFooter from "@/components/SiteFooter";
import MenuBar from "@/components/MenuBar";
import ScaffFrame from "@/components/ScaffFrame";
export default {
  name: "Scaffold",
  title: "RYE Group for Scaffold Consulting",
  meta:
    "Temporary works (TW) are the parts of a construction project that are needed to enable the permanent works to be built. BS5975:2019 provides an industry consensus view on what is considered to  be good practice. The legal requirement is that the party in control must ensure that work is allocated and carried out in a manner that does not create unacceptable risk of harm to workers or members of the public. On projects with relatively simple Temporary Works needs, you may choose not to appoint a Temporary Works Coordinator. However, you must still make sure that Temporary Works are properly managed to ensure safety.  RYE Safety will provide a cost effective and knowledgeable partner for your business.",
  components: {
    ScaffFrame,
    MenuBar,
    SiteFooter,
    BottomSection,
    TopSection
  },
  data() {
    return {
      load: false,
      imageSliderSettings: {
        infiniteScroll: true,
        autoPlay: true,
        playSpeed: 200,
        transition: 1500,
        wheelControl: false,
        hoverPause: false,
        vertical: false,
        itemsToShow: 2,
        breakpoints: {
          1200: {
            itemsToShow: 5,
            centerMode: false
          }
        }
      }
    };
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
    }, 300);
  }
};
</script>

<style scoped>
.hooper {
  @apply h-96;
}
</style>
