<template>
  <div>
    <menu-bar></menu-bar>
    <div class="flex items-center bg-black min-h-screen ">
      <div class="container mx-auto mt-20">
        <div
          class="container mx-auto my-10 bg-white p-5 rounded-md shadow-sm grid grid-cols-6 md:grid-cols-12"
        >
          <div class="col-span-6 relative flex-grow">
            <transition name="fade">
              <div
                class="absolute top-0 left-0 w-full h-full z-10 bg-white flex justify-center items-center"
                v-if="this.sent"
              >
                <p class="text-2xl font-impact text-brand-orange">
                  Thanks for your message. We'll be in touch soon.
                </p>
              </div>
            </transition>
            <div class="text-center">
              <h1 class="my-3 text-4xl font-impact text-brand-orange">
                Contact Us
              </h1>
              <p class="text-gray-400 dark:text-gray-400">
                Fill the form below to send us a message.
              </p>
            </div>
            <div class="m-7">
              <form action="#" method="POST" id="form" @submit="sendForm">
                <div class="mb-6">
                  <label
                    for="name"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                    >Full Name</label
                  >
                  <input
                    v-model="name"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="John Doe"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="email"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                    >Email Address</label
                  >
                  <input
                    v-model="email"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="you@company.com"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="phone"
                    class="text-sm text-gray-600 dark:text-gray-400"
                    >Phone Number</label
                  >
                  <input
                    v-model="phone"
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="+44 7392 123456"
                    required
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="message"
                    class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                    >Your Message</label
                  >

                  <textarea
                    v-model="message"
                    rows="5"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                    required
                  ></textarea>
                </div>
                <div class="mb-6">
                  <button
                    type="submit"
                    class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
                  >
                    Send Message
                  </button>
                </div>
                <p class="text-base text-center text-gray-400" id="result"></p>
              </form>
            </div>
          </div>
          <div
            class="flex flex-col justify-between items-center text-4xl font-impact pr-4 pb-4  col-span-6 "
          >
            <div></div>
            <div class="flex justify-center items-center">
              <p class="font-impact text-4xl hidden sm:block mb-4 lg:mb-0 px-4">
                We look forward to helping you and will get back to you as soon
                as possible. <br /><br />If your enquiry is urgent then please
                call.
              </p>
            </div>
            <div>
              <p class="mb-2">
                <font-awesome-icon
                  icon="envelope"
                  class="mr-2"
                ></font-awesome-icon
                ><a href="mailto:info@rye.group">INFO@RYE.GROUP</a>
              </p>
              <p>
                <font-awesome-icon icon="phone" class="mr-2"></font-awesome-icon
                >+44 7391 705 046
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuBar from "@/components/MenuBar";
import axios from "axios";
export default {
  name: "Contact",
  title: "Contact RYE Group",
  components: { MenuBar },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      sent: false
    };
  },
  methods: {
    sendForm(e) {
      e.preventDefault();
      axios
        .post(
          "https://6fw5m1xd45.execute-api.eu-west-2.amazonaws.com/send-ses",
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.message
          }
        )
        .then(() => {
          this.sent = true;
          this.phone = "";
          this.name = "";
          this.message = "";
          this.email = "";
          setTimeout(() => {
            this.sent = false;
          }, 2000);
        });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
